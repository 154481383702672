import '../styles/footer.css';
import React from 'react';
import candle from "../images/candle.png";
import gallery from "../images/fantom-gallery.png";
import twitter from "../images/twitter.png";
import discord from "../images/discord.png";

function Footer() {
return <div className="footer">
<div className="social-links">
    <a href="https://fantomgallery.io"><img src={gallery} alt="discord"/></a>
    <a href="https://twitter.com/cultnfts"><img src={twitter} alt="twitter"/></a>
    <a href="https://discord.gg/cultnfts"><img src={discord} alt="twitter"/></a>
    <a href="https://candle.farmgod.finance"><img src={candle} alt="twitter"/></a>
  </div>
  <div className='footer-text'><p>Cult NFTs</p></div>
</div>
}
export default Footer;